import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import ImprintPage from '../ui/pages/ImprintPage'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/de.json'

const Page = props => (
  <>
    <HelmetComponent
      title={metaTags.indexTitle}
      description={metaTags.legal}
      page="de/imprint"
    />
    <AppWrapper {...props} lang="de">
      <ImprintPage />
    </AppWrapper>
  </>
)

export default Page
