import React, { forwardRef } from 'react'
import cx from 'classnames'

const Wrapper = forwardRef(({ children, className }, ref) => {
  return (
    <div className={cx('wrapper', { [className]: className })} ref={ref}>
      {children}
    </div>
  )
})

export default Wrapper
