import React from 'react'
import MainLayout from '../layouts/MainLayout'
import ImprintView from '../views/ImprintView'

const ImprintPage = () => {
  return (
    <MainLayout footerTiles={false}>
      <ImprintView />
    </MainLayout>
  )
}

export default ImprintPage
